import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MobileUser } from '../models/mobile-user';

@Injectable({providedIn: 'root'})
export class MobileUserService {
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    getAll() {
        return this.httpClient.get<any>(this.config.apiUrl + '/mobileusers/all');
    }

    getAllWithRegistrationOnFirebase() {
        return this.httpClient.get<any>(this.config.apiUrl + '/mobileusers/all_with_firabase_registration');
    }

    filterAccessibleUsersWithFirebase(filterValue) {
        return this.httpClient.post<any>(this.config.apiUrl + '/mobileusers/filter_all_with_firabase_registration',
        {'filter_value' : filterValue});
    }

    getAllWithParams(params: any) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/mobileusers', {
            params: httpParams,
            observe: 'body'
        });
    }

    getCountryAlertedWithParams(params: any, country_id: number) {
        let httpParams = new HttpParams();
        Object.keys(params).forEach(function (key) { httpParams = httpParams.append(key, JSON.stringify(params[key])); });
        return this.httpClient.get<any>(this.config.apiUrl + '/mobileusers/alerted_for_country/' + country_id, {
            params: httpParams,
            observe: 'body'
        });
    }

    getById(_id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/mobileusers/' + _id);
    }

    getByUsersIds(_ids: any[]) {
        return this.httpClient.post(
            this.config.apiUrl + '/mobileusers/get_all_with_ids',
            {'pob_ids': _ids}
        );
    }

    getAlertedCountries(userId: number){
        return this.httpClient.get<any>(this.config.apiUrl + '/mobileusers/' + userId + '/alerted_countries');
    }

    getUserResponders(userId: number){
        return this.httpClient.get<any>(this.config.apiUrl + '/mobileusers/' + userId + '/my_requests');
    }

    create(user) {
        return this.httpClient.post(this.config.apiUrl + '/mobileusers', user);
    }

    update(user) {
        return this.httpClient.put(this.config.apiUrl + '/mobileusers/' + user.id, user);
    }

    delete(_id: number) {
        return this.httpClient.delete(this.config.apiUrl + '/mobileusers/' + _id);
    }

    blockUser(id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/mobileusers/' + id + '/block');
    }

    unblockUser(id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/mobileusers/' + id + '/unblock');
    }

    confirmUserEmail(id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/mobileusers/' + id + '/confirm_email');
    }

    confirmUserSponsor(id: number) {
        return this.httpClient.get<any>(this.config.apiUrl + '/mobileusers/' + id + '/confirm_sponsor');
    }

    deleteBulkOfUsers(users: MobileUser[]) {
        let ids = [];
        if(users && users.length > 0) { ids = users.map(a => a.id); }
        return this.httpClient.post(this.config.apiUrl + '/mobileusers/bulkdelete', {"ids": ids});
    }

    downloadUsersCSV(showCorporateUsersTable, showIncompleteRegInfo) {
        return this.httpClient.get(this.config.apiUrl + 
            '/mobileusers/corporate/' + ((showCorporateUsersTable)?1:0) + 
            '/incomplete_registrations/' + ((showIncompleteRegInfo)?1:0) + 
            '/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv' ),
            observe: 'response',
            responseType: 'blob'
        });
    }

    downloadCountrySubscribedUsersCSV(countryId: number) {
        return this.httpClient.get(this.config.apiUrl 
            + '/mobileusers/countries/' 
            + countryId + '/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv' ),
            observe: 'response',
            responseType: 'blob'
        });
    }

    downloadCountryAlertedUsersCSV(countryId: number) {
        return this.httpClient.get(this.config.apiUrl 
            + '/mobileusers/alerted_for_country/' 
            + countryId + '/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv' ),
            observe: 'response',
            responseType: 'blob'
        });
    }

    exportCountryAlertedUsersPdf(countryId: number) {
        return this.httpClient.get(this.config.apiUrl 
            + '/mobileusers/alerted_for_country/' 
            + countryId + '/pdf', {
            headers: new HttpHeaders().set('Accept', 'application/pdf' ),
            observe: 'response',
            responseType: 'blob'
        });
    }

    downloadUserInfo(userId: number) {
        return this.httpClient.get(this.config.apiUrl + 
            '/mobileusers/' + userId + '/user_info/csv', {
            headers: new HttpHeaders().set('Accept', 'text/csv' ),
            observe: 'response',
            responseType: 'blob'
        });
    }

    changeStatus(statusLetter, userId) {
        return this.httpClient.put<any>(this.config.apiUrl + '/mobileusers/' + userId + '/status',
        {'status_letter' : statusLetter});
    }

    linkUsersByEmails(userId:number, ssoLogId: number, ssoLogEmail: string){
        return this.httpClient.post(this.config.apiUrl + '/mobileusers/' 
        + userId + '/sso_log/' + ssoLogId + '/link_sso_email_to_user_email', 
        {
            'sso_email': ssoLogEmail
        });
    }

}
